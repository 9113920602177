var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.OrderDetailPricePart.title",
            expression: "'components.OrderDetailPricePart.title'"
          }
        ]
      }),
      _c("CCardBody", [
        _c(
          "table",
          { staticClass: "table", attrs: { "aria-describedby": "Rechnung" } },
          [
            _c("thead", [
              _c("tr", [
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.position",
                      expression: "'components.OrderDetailPricePart.position'"
                    }
                  ],
                  attrs: { scope: "col" }
                }),
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.type",
                      expression: "'components.OrderDetailPricePart.type'"
                    }
                  ],
                  attrs: { scope: "col" }
                }),
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.name",
                      expression: "'components.OrderDetailPricePart.name'"
                    }
                  ],
                  attrs: { scope: "col" }
                }),
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.count",
                      expression: "'components.OrderDetailPricePart.count'"
                    }
                  ],
                  attrs: { scope: "col" }
                }),
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.singlePrice",
                      expression:
                        "'components.OrderDetailPricePart.singlePrice'"
                    }
                  ],
                  attrs: { scope: "col" }
                }),
                _c("th", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.OrderDetailPricePart.totalPrice",
                      expression: "'components.OrderDetailPricePart.totalPrice'"
                    }
                  ],
                  attrs: { scope: "col" }
                })
              ])
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.order.lineItems, function(lineItem) {
                  return _c(
                    "tr",
                    {
                      key: lineItem.Id,
                      attrs: { "data-position": lineItem.position }
                    },
                    [
                      _c("td", [_vm._v(_vm._s(lineItem.position))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("enums.lineItemType." + lineItem.type))
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(lineItem.text))]),
                      _c("td", [
                        _vm._v(_vm._s(lineItem.amount.toLocaleString("de")))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatCurrency(lineItem.price)))
                      ]),
                      _c("td", [
                        lineItem.amount > 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatCurrency(
                                    lineItem.amount * lineItem.price
                                  )
                                )
                              )
                            ])
                          : _vm._e(),
                        lineItem.amount <= 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.formatCurrency(lineItem.price)))
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                _vm.order.totalAmount != _vm.order.amount
                  ? _c("tr", { staticClass: "total-row" }, [
                      _c("td"),
                      _c("td"),
                      _c("td"),
                      _c("td", { staticClass: "total-heading" }, [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.OrderDetailPricePart.subTotal"
                                )
                              )
                            )
                          ])
                        ])
                      ]),
                      _c("td"),
                      _c("td", { staticClass: "total-amount" }, [
                        _vm._v(_vm._s(_vm.formatCurrency(_vm.order.amount)))
                      ])
                    ])
                  : _vm._e(),
                _vm.showVoucherInfo
                  ? _c("tr", { staticClass: "total-row" }, [
                      _c("td"),
                      _c("td"),
                      _c("td"),
                      _c("td", { staticClass: "total-heading" }, [
                        _c("div", [
                          _c(
                            "span",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.OrderDetailPricePart.voucherDiscount"
                                    )
                                  ) +
                                  " (Code: "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "HostVoucherEdit",
                                      params: {
                                        voucherId: _vm.order.voucher.id
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.order.voucher.code) + " "
                                  )
                                ]
                              ),
                              _vm._v(" ) ")
                            ],
                            1
                          ),
                          _vm.order.voucher.amount > 0
                            ? _c("div", [
                                _c("small", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.OrderDetailPricePart.voucherAmountDiscountInfo",
                                          { amount: _vm.order.voucher.amount }
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.order.voucher.percentage > 0
                            ? _c("div", [
                                _c("small", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.OrderDetailPricePart.voucherPercentageDiscountInfo",
                                          {
                                            percentage:
                                              _vm.order.voucher.percentage
                                          }
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.showPlatformVoucherHostInfo
                            ? _c(
                                "span",
                                [
                                  _vm._v(" "),
                                  _c("CIcon", {
                                    directives: [
                                      {
                                        name: "c-tooltip",
                                        rawName: "v-c-tooltip",
                                        value: {
                                          appendToBody: true,
                                          content: _vm.$t(
                                            "components.OrderDetailPricePart.platformVoucherHostInfo"
                                          )
                                        },
                                        expression:
                                          "{\n                    appendToBody: true,\n                    content: $t('components.OrderDetailPricePart.platformVoucherHostInfo'),\n                  }"
                                      }
                                    ],
                                    attrs: { name: "cil-info-circle" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c("td"),
                      _c("td", { staticClass: "total-amount" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatCurrency(_vm.order.voucherDiscount * -1)
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("td"),
                  _c("td"),
                  _c("td"),
                  _c("td", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "components.OrderDetailPricePart.total",
                        expression: "'components.OrderDetailPricePart.total'"
                      }
                    ]
                  }),
                  _c("td"),
                  _c("td", [
                    _vm._v(_vm._s(_vm.formatCurrency(_vm.order.totalAmount)))
                  ])
                ]),
                _vm.showTax && _vm.reducedTax > 0
                  ? _c("tr", [
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailPricePart.reducedTax",
                              { rate: _vm.reducedTaxRate }
                            )
                          )
                        )
                      ]),
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatCurrency(_vm.reducedTax)))
                      ])
                    ])
                  : _vm._e(),
                _vm.showTax && _vm.regularTax > 0
                  ? _c("tr", [
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.OrderDetailPricePart.regularTax",
                              { rate: _vm.regularTaxRate }
                            )
                          )
                        )
                      ]),
                      _c("td", { staticClass: "no-boarder" }),
                      _c("td", { staticClass: "no-boarder" }, [
                        _vm._v(_vm._s(_vm.formatCurrency(_vm.regularTax)))
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        ),
        _c("small", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.OrderDetailPricePart.totalInfo",
              expression: "'components.OrderDetailPricePart.totalInfo'"
            }
          ]
        }),
        _c(
          "div",
          [
            _vm.showHostInfo && _vm.order.canDownloadInvoice
              ? _c(
                  "CButton",
                  {
                    staticClass: "mr10",
                    attrs: { color: "secondary" },
                    on: { click: _vm.handleDownloadInvoice }
                  },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.hostOrderDetailButtons.downloadInvoice",
                          expression:
                            "'components.hostOrderDetailButtons.downloadInvoice'"
                        }
                      ]
                    })
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }