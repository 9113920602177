var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.OrderDetailImportantInformationPart.title",
            expression: "'components.OrderDetailImportantInformationPart.title'"
          }
        ]
      }),
      _c("CCardBody", [
        _c("ul", [
          _vm.order.cancellation
            ? _c(
                "li",
                [
                  _c("CancellationInformation", { attrs: { order: _vm.order } })
                ],
                1
              )
            : _vm._e(),
          _c("li", [
            _vm.prepayment > 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailImportantInformationPart.prepayment",
                          {
                            percentage: _vm.prepayment,
                            amount: _vm.formatCurrency(
                              (_vm.prepayment * _vm.order.amount) / 100
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.prepayment == 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailImportantInformationPart.no_prepayment"
                        )
                      ) +
                      " "
                  )
                ])
              : _vm._e()
          ]),
          _c("li", [
            _vm.deposit > 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailImportantInformationPart.deposit",
                          { amount: _vm.deposit }
                        )
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _vm.deposit == 0
              ? _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.OrderDetailImportantInformationPart.noDeposit"
                        )
                      ) +
                      " "
                  )
                ])
              : _vm._e()
          ]),
          _c("li", [
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "components.OrderDetailImportantInformationPart.acceptedPayments"
                  )
                )
              )
            ]),
            _c("div", { staticClass: "payments" }, [
              _vm.order.acceptsCash
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c("CIcon", { attrs: { name: "cilCash", size: "xl" } })],
                      1
                    ),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.OrderDetailImportantInformationPart.acceptsCash"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.order.acceptsCreditCard
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("CIcon", {
                          attrs: { name: "cilCreditCard", size: "xl" }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.OrderDetailImportantInformationPart.acceptsCreditCard"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.order.acceptsPaypal
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("CIcon", {
                          attrs: { name: "cibCcPaypal", size: "xl" }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.OrderDetailImportantInformationPart.acceptsPaypal"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.order.acceptsInvoice
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c("CIcon", {
                          attrs: { name: "cilDocument", size: "xl" }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.OrderDetailImportantInformationPart.acceptsInvoice"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }