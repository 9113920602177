var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isImpossible
      ? _c("div", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.CancellationInformation.noCancellation",
              expression: "'components.CancellationInformation.noCancellation'"
            }
          ]
        })
      : _vm._e(),
    _vm.hasFee
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.CancellationInformation.cancellation", {
                  percentage: _vm.order.cancellation.percentage.toLocaleString(),
                  validUntil: _vm.validUntil
                })
              ) +
              " "
          )
        ])
      : _vm._e(),
    _vm.has100Fee
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.CancellationInformation.cancellation100Percent"
                )
              ) +
              " "
          )
        ])
      : _vm._e(),
    _vm.isFree
      ? _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.CancellationInformation.freeCancellation", {
                  validUntil: _vm.validUntil
                })
              ) +
              " "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }